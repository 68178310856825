<!-- 新增编辑问答库 -->
<template>
  <div class='box-card'>
    <el-form :model="batchAddForm" :rules="batchAddFormRules" ref="batchAddFormRef" label-width="120px" class="form_box">
      <el-form-item label="标题：" prop="questions_desc">
        <el-input v-model="batchAddForm.questions_desc" placeholder="请输入问题描述" maxlength="100"
          @input="(e) => (batchAddForm.questions_desc = validSpacing(e))"></el-input>
      </el-form-item>
      <el-form-item label="问题：" prop="questions_sample">
        <el-input type="textarea" v-model="batchAddForm.questions_sample"  :rows="2" placeholder="请输入问题"></el-input>
      </el-form-item>
      <div v-for="(item, index) in batchAddForm.answers" :key="index">
        <el-form-item :label="'答案'+(index+1)+'：'"  :prop="`answers[${index}]`"  :rules="[{ required: true, message: '请选输入答案', trigger: 'blur' }]" >
          <div class="answerBox">
            <el-input type="textarea" v-model="batchAddForm.answers[index]" :rows="3" placeholder="请输入答案"></el-input>
            <el-button icon="el-icon-plus" v-if="index === 0" size="mini" circle
                @click="addBillingTime(index)"></el-button>
              <el-button icon="el-icon-minus" v-if="index > 0" size="mini" circle
                @click="delBillingTime(index)"></el-button>
          </div>
        </el-form-item>
      </div>
      <el-form-item label="关键词：" prop="inputValue">
        <el-tag :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false" @close="handleClose(tag)">
          {{ tag }}
        </el-tag>
        <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" maxlength="50" ref="saveTagInput" size="small"  @input="(e) => (inputValue = divideSymbolDot(e))"
          @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 关键词</el-button>
      </el-form-item>
      <el-form-item label="匹配个数：" prop="matching_number">
        <el-input v-model="batchAddForm.matching_number" placeholder="请输入匹配个数"
          @input="(e) => (batchAddForm.matching_number = validNumberInteger(e))"></el-input>
      </el-form-item>
    </el-form>
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitAddForm" v-preventReClick>保 存
    </lbButton>
  </div>
</template>

<script>
import { addAiQuestionsAnswers, updateAiQuestionsAnswers, getAiQuestionsAnswersDetails } from '@/api/maintenanceCenter.js'
export default {
  name: 'addFAQLibrary',
  components: {},
  props: ['id', 'active'],
  data () {
    var validateinputValue = (rule, value, callback) => {
      console.log('value', this.dynamicTags)
      if (this.dynamicTags.length === 0) {
        // 没有配置关键词
        callback(new Error('请输添加关键词'))
      } else {
        callback()
      }
    }
    // var validateAnswers = (rule, value, callback) => {
    //   console.log('value', this.batchAddForm.answers)
    //   const answersList = this.batchAddForm.answers
    //   let empty = 0
    //   answersList.filter(element => {
    //     if (!element) {
    //       empty++
    //     }
    //   })
    //   if (empty > 0) {
    //     callback(new Error('请输添加答案'))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      dynamicTags: [], // 关键词数据
      inputVisible: false,
      inputValue: '',
      batchAddFormRules: {
        questions_desc: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        questions_sample: [
          { required: true, message: '请输入问题', trigger: 'blur' }
        ],
        // answers: [
        //   { required: true, validator: validateAnswers, trigger: 'blur' }
        // ],
        inputValue: [
          { required: true, validator: validateinputValue, trigger: 'blur' }
        ],
        matching_number: [
          { required: true, message: '请输入匹配个数', trigger: 'blur' }
        ]
      }, // 校验规则
      // 表单数据
      batchAddForm: {
        matching_number: '', // 匹配个数
        answers: [''], // 答案
        keywords: '', // 关键词
        questions_sample: '', // 问题
        questions_desc: '', // 标题
        is_active: false // 是否禁用
      }
    }
  },
  watch: {},
  computed: {},
  methods: {
    handleClose (tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
    },

    showInput () {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm () {
      const inputValue = this.inputValue
      if (inputValue) {
        this.dynamicTags.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    // 获取问答详情
    async fngetAiQuestionsAnswersDetails () {
      const res = await getAiQuestionsAnswersDetails({ _id: this.id })
      console.log('详情', res)
      if (res.Data && res.Code === 200) {
        this.batchAddForm.matching_number = res.Data.matching_number
        this.batchAddForm.answers = res.Data.answers
        this.batchAddForm.questions_sample = res.Data.questions_sample
        this.batchAddForm.questions_desc = res.Data.questions_desc
        this.batchAddForm.keywords = res.Data.keywords
        // 处理关键词
        this.dynamicTags = this.batchAddForm.keywords.split(',')
      }
    },
    // 增加答案按钮
    addBillingTime () {
      this.batchAddForm.answers.push('')
    },
    // 删除答案按钮
    delBillingTime (index) {
      this.batchAddForm.answers.splice(index, 1)
    },
    // 新增问答库
    async fnAddQuestionLibrary () {
      const res = await addAiQuestionsAnswers(this.batchAddForm)
      if (res && res.Code === 200) return this.$emit('closeDialog', false)
    },
    // 编辑问答库
    async fnUpdateQuestionLibrary () {
      this.batchAddForm._id = this.id
      const res = await updateAiQuestionsAnswers(this.batchAddForm)
      if (res && res.Code === 200) return this.$emit('closeDialog', false)
    },
    // 保存
    submitAddForm () {
      // // 去除答案为空的列
      // const filterAnswersList = this.batchAddForm.answers.filter(item => {
      //   const filterAnswer = item.trim()
      //   return filterAnswer !== ''
      // })
      // this.batchAddForm.answers = filterAnswersList
      // 保留原始开启状态
      this.batchAddForm.is_active = this.active

      // // 去除完之后添加一项空答案
      // if (this.batchAddForm.answers.length === 0) {
      //   this.batchAddForm.answers.push('')
      // }
      // 对整个表单进行校验
      this.$refs.batchAddFormRef.validate((valid) => {
        // 成功
        if (valid) {
          // 处理关键词数据
          this.batchAddForm.keywords = this.dynamicTags.join(',')
          if (this.id) {
            this.fnUpdateQuestionLibrary()
          } else {
            this.fnAddQuestionLibrary()
          }
        } else {
          return false
        }
      })
    }
  },
  created () { },
  mounted () {
    if (this.id) {
      // 获取问答信息
      this.fngetAiQuestionsAnswersDetails()
    }
  }
}
</script>

<style scoped lang="less">
.el-tag+.el-tag {
  margin-left: 10px;
}
.el-form-item{
  width: 95%;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.answerBox{
  display: flex;
  align-items: center;
  .el-button {
    margin-left: 10px;
    padding: 8px !important;
    height: 40px;
    width: 40px;
  }
}
.box-card {
    position: relative;
    padding-bottom: 30px;
    .form_box{
      max-height: 650px;
    overflow-y: auto;
    overflow-x: hidden;
    }
    .checkButton {
        position: absolute;
        right: 0px;
    }

    .checkButton2 {
        position: absolute;
        right: 100px;
    }
}
</style>
